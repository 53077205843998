import { FC } from 'react';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { ComparisonHeaderMobile } from './mobile';
import { ComparisonHeaderDesktop } from './desktop';

export interface IComparisonHeader {
  apartments: IApartment[];
}

export const ComparisonHeader: FC<IComparisonHeader> = ({ apartments }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {isMobile ? (
        <ComparisonHeaderMobile apartments={apartments} />
      ) : (
        <ComparisonHeaderDesktop apartments={apartments} />
      )}
    </>
  );
};
